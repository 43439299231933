<!-- ####################################################### Products -->
<template>
  <div class="w-full" style="">
    <div v-if="am_allowed('product.can_see_product')">
      <div class="columns mx-0" :class="{ 'half-height mb-0 overfllow-y-scroll-mobile': selected_item.id }">
        <div class="column is-flex is-flex-direction-column pb-0 overflow-auto"
          :class="{ 'overfllow-y-scroll': selected_item.id }">
          <div class="p-0 m-0">
            <div class="level-left">
              <grid-col-manage
                :columnDefs="columnDefs.filter(f => !['action', 'name', 'is_accessory'].includes(f.field))"
                :hiden_columns="hiden_columns" @toggle_column="toggle_column"></grid-col-manage>
              <div class="level-item">Katalogu i produkteve</div>
              <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..."
                v-model="global_search_filter" @input="onFilterTextBoxChanged" />
            </div>
            <div class="level-right">
              <div class="level">
                <a @click.prevent="openModal('services')" class="pr-2 mr-2 border-r">
                  <small> Sherbime </small>
                </a>
                <a @click.prevent="show_brands_krudh('brands')" class="pr-2 mr-2 border-r">
                  <small> Marka </small>
                </a>
                <a v-if="am_allowed('product.can_create_product')" @click.prevent="create_product">
                  <i class="fa fa-plus"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="is-flex-grow-1 overflow-x-scroll">
            <!-- <ag-grid-vue style="width: 100%; min-height: 40vh; height 500px!important;"  -->
            <!--   class="ag-theme-balham " -->
            <!--   :columnDefs="columnDefs" :rowData="rowData.value" :rowSelection="rowSelection" :context="context" -->
            <!--   :localeText="localeText" :animateRows="true" @grid-ready="onGridReady"> -->
            <!-- </ag-grid-vue> -->
            <dable :headers="columnDefs" :data="rowsFiltered" @save-changes="toggle_sublit_modal" :entity="'product'"
              :hidden_columns="hiden_columns" v-if="rowsFiltered && columnDefs" :currPage="current_page" />
          </div>
        </div>
        <div :class="{ 'overfllow-y-scroll': selected_item.id }" class="column is-5 border-b sticky-form"
          id="product-form-containers" v-if="form_edit ||
      form_create ||
      form_create_product_recipe ||
      form_edit_product_recipe
      ">
          <DaForm v-if="form_create" @submitted="submitProduct" :form="form_create" @close="form_create = null"
            :closes_form="true" :is_overflow="selected_item.id ? true : false">
          </DaForm>

          <DaForm v-if="form_edit" @submitted="submitProduct" :form="form_edit" @close="form_edit = null"
            :closes_form="true" :is_overflow="selected_item.id ? true : false">
          </DaForm>

          <table v-if="form_create_product_recipe || form_edit_product_recipe" class="table is-fullwidth">
            <thead>
              <tr>
                <th>Produkti</th>
                <th>Materiali</th>
                <th>Sasia</th>
                <th>Njesia</th>
                <th>Modifiko</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product_recipe in product_recipes" :key="product_recipe.id">
                <td>{{ product_recipe.product_name }}</td>
                <td>{{ product_recipe.material_name }}</td>
                <td>{{ product_recipe.quantity }}</td>
                <td>{{ product_recipe.unit_name }}</td>
                <td>
                  <a @click.prevent="edit_product_recipe(product_recipe)">
                    <i class="fa fa-pencil"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <DaForm v-if="form_create_product_recipe" @submitted="submitProductRecipe"
            @close="form_create_product_recipe = null" :closes_form="true" :form="form_create_product_recipe">
          </DaForm>

          <DaForm v-if="form_edit_product_recipe" @submitted="submitProductRecipe"
            @close="form_edit_product_recipe = null" :closes_form="true" :form="form_edit_product_recipe">
          </DaForm>
        </div>
      </div>

      <div v-if="selected_item.id" class="border-t-2 border-slate-200 mt-4 pt-2">
        <div class="is-flex">
          <a @click.prevent="close_selected_item()" href="" class="delete is-small ml-auto mb-2 mr-2"></a>
        </div>
        <div class="columns ">
          <div class="column is-4">
            <product-details :selected_item="selected_item"
              :attributes_per_item="attributes_per_item"></product-details>
          </div>
          <div class="column is-8">
            <div class="">
              <div class="tabs is-fullwidth is-boxed is-size-7">
                <ul>
                  <li :class="{ 'is-active': show_tab == 1 }">
                    <a @click.prevent="show_tab = 1">Ditare</a>
                  </li>
                  <li :class="{ 'is-active': show_tab == 2 }">
                    <a @click.prevent="show_tab = 2">Porosi</a>
                  </li>
                  <li :class="{ 'is-active': show_tab == 3 }">
                    <a @click.prevent="show_tab = 3">Gjendje</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="px-2" style="border: solid 1px #dbdbdb; border-width: 0px 1px">
              <div v-if="show_tab == 1">
                <product-diary-tab
                  :guid="selected_item.guid"
                ></product-diary-tab>
              </div>
              <div v-if="show_tab == 2">
                <product-process-tab
                  :guid="selected_item.guid"
                ></product-process-tab>
              </div>
              <div v-if="show_tab == 3">
                <product-stock v-if="show_tab == 3" :product="selected_item"></product-stock>
              </div>
              <div v-if="show_tab == 4">Comp 4 ...</div>
            </div>
          </div>
        </div>
      </div>

      <group-change-modal
        :show_group_change_modal="show_group_change_modal"
        @toggle_sublit_modal="toggle_sublit_modal"
        @save_changes="save_changes"
      ></group-change-modal>

    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
// import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
// import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createProduct from "./forms/product form/create";
import editProduct from "./forms/product form/edit";
import createProductRecipe from "./forms/product-recipe form/create_product_recipe";
import editProductRecipe from "./forms/product-recipe form/edit_product_recipe";
import locale from "@/components/ag-grid/locale/al/al.js";
import CategoriesServices from "@/services/Category";
import ProductsServices from "@/services/Product";
import ConfigurationService from "@/services/Configuration.js";

// import productTransactionsReport from "./components/product-transactions-report";
import productDiaryTab from "./components/product-diary-tab";
import productProcessTab from "./components/product-process-tab";
import productDetails from "./components/product-details";
import productStock from "./components/product-stock";
import socket from "@/socket.js";
import { onUnmounted, reactive, inject } from "vue";
import permissionMixin from "@/mixins/permissions-mixin.js";
import { defineAsyncComponent } from "vue";
import { $vfm } from "vue-final-modal";
var numeral = require("numeral");
import Api from "@/services/Api";
import gridColManage from "@/components/grid_col_manage.vue";
import dable from "@/components/Dable.vue";
import ProductServices from "@/services/Product";
import groupChangeModal from "./components/group_change_modal";
import WarehousesServices from "@/services/Warehouse";
// import { $vfm } from 'vue-final-modal'
// import ConfigurationService from "@/services/Configuration.js"
// import { useKrudhStore } from "@/stores/krudh_communication";
// import { storeToRefs } from "pinia";

export default {
  mixins: [permissionMixin],
  components: {
    // AgGridVue,
    DaForm,
    // productTransactionsReport,
    productDetails,
    productStock,
    gridColManage,
    dable,
    groupChangeModal,
    productDiaryTab,
    productProcessTab
    // ButtonsCell,
  },
  setup() {
    let rowData = reactive([]);
    const toast = inject("toast");
    socket.connect();
    socket.on("welcome", (data) => {
      console.log("welcome", data);
    });
    socket.on("propagate", (data) => {
      if (data.entity == "products") {
        if (data.action == "create") {
          var a = rowData.value;
          a.push(data.data);
          rowData.value = [...a];
          toast.success(
            "Produkti u krijua <i>nga " + data.created_by.name + "</i>"
          );
        }
        if (data.action == "edit") {
          rowData.value = [
            ...rowData.value.map((item) => {
              if (item.id == data.data.id) {
                return data.data;
              }
              return item;
            }),
          ];
          toast.success(
            "Produkti u modifikua <i>nga " + data.created_by.name + "</i>"
          );
        }
        if (data.action == "delete") {
          rowData.value = [
            ...rowData.value.filter((item) => {
              return item.id != data.data.id;
            }),
          ];
        }
      }
    });
    onUnmounted(() => {
      socket.off("welcome");
      socket.off("propagate");
      socket.disconnect();
    });
    return {
      rowData,
    };
  },
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      current_page: 1,
      is_product_recipe: "",
      product_recipes: [],
      selectedProduct: {},
      selectedProductRecipe: {},
      form_create_product_recipe: null,
      form_edit_product_recipe: null,
      form_create: null,
      form_edit: null,
      // rowData   : [],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        // flex: 1,
        // minWidth: 400,
      },
      rowSelection: "single",
      localeText: null,
      show_tab: 1,
      selected_item: {},
      attributes_per_item: {},
      services_per_item: {},
      service_types_list: [],
      services_eav_data: [],
      show_services_col: true,
      hiden_columns: [
        'price',
        'vat',
        'unit_name',
        'known_as',
        'origin',
        'manager',
        'energy_efficiency',
        'packaging',
        'warranty_airbag',
        'in_web',
        'energy_efficiency_name',
      ],
      managers: [],
      origin_countries: [],
      warranties: [],
      energy_efficiency: [],
      show_group_change_modal: false,
      submit_changes_data: null,
      warehouses_list: []
    };
  },
  methods: {
    submitChanges(data) {
      // console.log(data);
      Api(true).post('product/save/changes', { 'products': data, reason_text: '', change_type: 1 })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggle_sublit_modal(data){
      this.show_group_change_modal = !this.show_group_change_modal,
      this.submit_changes_data = data
    },
    save_changes(data){
      Api(true).post('product/save/changes', { 
          'products': this.submit_changes_data, 
          reason_text: data.reason_text, 
          change_type: data.change_type })
        .then(() => {
          this.submit_changes_data = null
          this.show_group_change_modal = false
          //toaster 
          this.$toast.success("Ndryshimi u ruajt me sukses.");

          window.location.reload()
        })
        .catch(() => {
          this.submit_changes_data = null
        });
    },
    toggle_column(column) {
      if (this.hiden_columns.includes(column)) {
        this.hiden_columns = [...this.hiden_columns.filter(item => item != column)]
      } else {
        this.hiden_columns.push(column)
      }
      // Save localstorave
      localStorage.removeItem("hiden_columns")
      localStorage.hiden_columns = JSON.stringify(this.hiden_columns)

      // setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    onFilterTextBoxChanged() {
      // this.gridApi.setQuickFilter(this.global_search_filter);
    },
    async open(mode, product) {
      // edit mode
      if (mode == "1") {

        if (this.am_allowed("product.can_edit_product")) {
          this.form_create = null;
          this.form_create_product_recipe = null;
          this.form_edit_product_recipe = null;
          this.form_edit = null;
          if (product) {
            // setTimeout(() => {
            //   this.gridApi.sizeColumnsToFit();
            // }, 100)
            this.form_edit = { loading: true };
            console.log(product);
            this.form_edit = await editProduct(product);
            // If screen width is smaller than 768px
            if (window.innerWidth < 768) {
              this.close_selected_item()
              // Scroll to form
              setTimeout(() => {
                const productFormContainers = document.getElementById('product-form-containers');
                if (productFormContainers) {
                  productFormContainers.scrollIntoView({ behavior: 'smooth' });
                }
              }, 1000);

            }
            // AG Grid show less columns
            this.selectedProduct = { ...product };
            // setTimeout(() => {
            //   this.gridApi.sizeColumnsToFit();
            // }, 100);
          }
        } else {
          this.$toast.error("Nuk keni te drejta te modifikoni produktin.");
        }
      }
      else if (mode == "0") {
        if (this.am_allowed("product.can_see_product_details")) {
          // this.form_edit = null;
          // this.form_create = null;

          this.selected_item = {};
          this.selected_item = await ProductsServices.get({
            guid: product.guid,
          });
          // this.gridApi.sizeColumnsToFit();
          setTimeout(async () => {
            let selected_category = {};
            selected_category[
              product.is_accessory == 0 ? product.type_id : product.accessory_id
            ] = true;
            this.attributes_per_item =
              await CategoriesServices.getCategoryAttributes(
                selected_category,
                product,
                true
              );

            // Get services categories ...
            this.services_per_item =
              await CategoriesServices.getCategoryAttributes(
                selected_category,
                product,
                true,
                "services"
              );
          });
        } else {
          this.$toast.error(
            "Nuk keni te drejta per te hapur te dhenat produktit."
          );
        }
      }
      else if (mode == "2") {
        // this.gridApi.sizeColumnsToFit();
        this.form_edit = null;
        this.form_edit_product_recipe = null;
        this.form_create = null;
        this.selectedProduct = {};
        this.product_recipes = await ProductsServices.getProductRecipes();
        var temp_pr = product;

        this.selectedProduct = temp_pr;
        this.form_create_product_recipe = { loading: true };
        this.form_create_product_recipe = await createProductRecipe();
      }
    },
    // onGridReady(params) {
    //   this.gridApi = params.api;
    //   this.gridApi.sizeColumnsToFit();
    //   this.gridColumnApi = params.columnApi;
    // },
    async create_product() {
      this.form_edit = null;
      this.form_edit_product_recipe = null;
      this.form_create_product_recipe = null;

      // setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.form_create = { loading: true };
      this.form_create = await createProduct();
      // setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);

      // AG Grid show less columns ...
    },
    async create_product_recipe() {
      this.form_edit = null;
      this.form_edit_product_recipe = null;
      this.form_create = null;
      this.form_create_product_recipe = { loading: true };
      this.form_create_product_recipe = await createProductRecipe();
      // this.gridApi.sizeColumnsToFit();
    },
    async edit_product_recipe(pr) {
      this.form_create_product_recipe = null;
      this.form_create = null;
      if (pr) {
        // this.gridApi.sizeColumnsToFit();
        this.form_edit_product_recipe = null;
        this.form_edit = null;
        this.form_edit_product_recipe = { loading: true };
        this.form_edit_product_recipe = await editProductRecipe(pr);
        this.selectedProductRecipe = pr;
      }
    },
    async submitProduct(p) {
      if (this.form_create != null) {
        var notify_text = "";
        let product = await ProductsServices.createProduct(p);
        var a = this.rowData.value;
        a.push(product);
        this.rowData.value = [...a];
        socket.emit("propagate", {
          entity: "products",
          action: "create",
          data: product,
        });
        notify_text = "Produkti u krijua me sukses";
        this.form_create = null;
        this.$root.selected_one = {};
      } else {
        p.guid = this.selectedProduct.guid;
        let product = await ProductsServices.editProduct(p);
        this.rowData.value = [
          ...this.rowData.value.map((pro) => {
            if (pro.guid == product.guid) pro = { ...product };
            return pro;
          }),
        ];
        socket.emit("propagate", {
          entity: "products",
          action: "edit",
          data: product,
        });
        notify_text = "Produkti u modifikua me sukses";
        this.form_edit = null;
        this.$root.selected_one = {};
      }
      // setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.$toast.success(notify_text);
    },
    async submitProductRecipe(pr) {
      if (this.form_create_product_recipe != null) {
        pr.product_id = this.selectedProduct.guid;
        var notify_text = "";
        let p_recipe = await ProductsServices.createProductRecipe(pr);
        var a = this.product_recipes;
        a.push(p_recipe);
        this.product_recipes = [...a];
        notify_text = "Produkti i perbere u krijua me sukses";
        this.form_create = null;
        this.form_create_product_recipe = null;
        this.$root.selected_one = {};
      } else {
        pr.id = this.selectedProductRecipe.id;
        let p_recipe = await ProductsServices.editProductRecipe(pr);
        this.product_recipes = this.product_recipes.map((prc) => {
          if (prc.id == p_recipe.id) prc = { ...p_recipe };
          return prc;
        });
        notify_text = "Produkti i perbere u modifikua me sukses";
        this.form_edit = null;
        this.form_edit_product_recipe = null;
        this.$root.selected_one = {};
      }
      // setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.$toast.success(notify_text);
    },
    openModal(modalName) {
      $vfm.show({
        component: defineAsyncComponent(() =>
          import("./components/" + modalName + "-modal.vue")
        ),
      });
    },
    async services_to_grid() {
      await Api(true)
        .post("product/services_data", {})
        .then((res) => {
          this.service_types_list = res.data.service_types_list;
          this.service_types_list.map(a => {
            this.hiden_columns.push(a.guid)
          })
          this.services_eav_data = res.data.services_eav_data;
        });

      // Add service value to rowData
      var a = this.services_eav_data;
      this.rowData.value = [
        ...this.rowData.value.map((p) => {
          a.map((eav) => {
            if (p.guid == eav.entity_id)
              p[eav.service_type_id] = eav.service_name;
          });
          return { ...p };
        }),
      ];
      // this.gridApi.sizeColumnsToFit();
    },
    toggle_services_col() {
      this.show_services_col = !this.show_services_col;
      // setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    show_brands_krudh(entity) {
      var params = {
        fields: [
          "name:Emri i markes",
          // 'icon:Ikona,*name:Emri',
          // 'description:Pershkrimi'
        ],
        entity: entity,
        columns: ["name:80%"],
        title: "Marka",
      };
      $vfm.show({
        component: defineAsyncComponent(() =>
          import("@/components/krudh-modal.vue")
        ),
        params,
      });
    },
    close_selected_item() {
      this.selected_item = { ...{} }
    },

    getWarehouseName(guid){
      return this.warehouses_list.find(w => w.guid == guid)?.name
    }

  },
  computed: {
    rowsFiltered() {
      if (this.global_search_filter == "") return this.rowData.value;
      return this.rowData.value.filter((row) => {
        return Object.keys(row).some((key) => {
          return String(row[key])
            .toLowerCase()
            .includes(this.global_search_filter.toLowerCase());
        });
      });
    },
    columnDefs() {
      var services_col_data = this.service_types_list.map((st) => {
        return {
          headerName: st.name,
          resizable: true,
          field: st.guid,
          filter: true,
          floatingFilter: true,
          sortable: true,
          hide: !this.show_services_col || this.hiden_columns.includes(st.guid),
          // show_with_form: true
        };
      });
      var aa = [
        {
          // is accessori aggrid field 
          headerName: "Aksesor",
          resizable: false,
          field: "is_accessory",
          floatingFilter: true,
          selectableColumn: true,
          sortable: true,
          width: '20px',
          pinned: 'left',
          cellRenderer: function (params) {
            let r = "";
            if (params.data.accessory_id != '' && params.data.is_accessory == 1) r = `<i class="fa fa-check text-green-500"></i>`
            // else r = `<i class="fa fa-times text-red-500"></i>`
            return "<div class='text-center'>" + r + "</div>";
          },
          hide: this.hiden_columns.includes("accessory_id"),

        },
        {
          headerName: "Kategoria",
          resizable: true,
          field: "type_id_name",
          filter: true,
          cellRenderer: function (params) {
            let r = "";
            if (!params.data.type_id && params.data.accessory_id == null) r = `<i class="fa fa-times text-red-500"></i>`
            else r = params.data.type_id_name;
            return r;
          },
          floatingFilter: true,
          sortable: true,
          // sort: "asc",
          // show_with_form: true,
          hide: this.hiden_columns.includes("type_id_name"),
        },
        {
          headerName: "Kodi",
          resizable: true,
          field: "code",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("code"),
        },
        {
          headerName: "Emri",
          resizable: true,
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: true,
          pinned: 'left',
          hide: this.hiden_columns.includes("name"),
        },
        {
          headerName: "Njesia",
          resizable: true,
          field: "unit_name",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: false,
          hide: this.hiden_columns.includes("unit_name"),
        },
        {
          headerName: "Marka",
          resizable: true,
          field: "brand_name",
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("brand_name"),
        },
        {
          headerName: "TVSH(%)",
          resizable: true,
          field: "vat",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("vat"),
        },
        {
          headerName: "Cmimi",
          resizable: true,
          field: "price",
          editable: {
            type: "number",
            field: "price",
            modules: {
              operation: true,
              number: true,
              percentage: true,
            },
            value: 0
          },
          cellRenderer: function (params) {
            let r = "";
            r = numeral(params.data.price).format("0,0");
            return "<div class='text-right mr-2'>" + r + "</div>";
          },
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("price"),
        },
        // {
        //   headerName: "Cmimi me TVSH",
        //   resizable: true,
        //   field: "price_with_vat",
        //   cellRenderer: function (params) {
        //     let r = "";
        //     r = numeral(params.data.price_with_vat).format("0,0");
        //     return "<div class='text-right mr-2'>" + r + "</div>";
        //   },
        //   filter: true,
        //   floatingFilter: true,
        //   sortable: true,
        //   // show_with_form: true,
        //   hide: this.hiden_columns.includes("price_with_vat"),
        // },
        {
          headerName: "Pershkrimi",
          resizable: true,
          field: "description",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: false,
          hide: this.hiden_columns.includes("description"),
        },
        {
          headerName: "Emri I njohur universal",
          resizable: true,
          field: "known_as",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: false,
          hide: this.hiden_columns.includes("known_as"),
        },
        {
          headerName: "Barkodi",
          resizable: true,
          field: "barcode",
          filter: true,
          floatingFilter: true,
          sortable: true,
          // show_with_form: false,
          hide: this.hiden_columns.includes("barcode"),
        },
        {
          headerName: "Origjina",
          resizable: true,
          field: "origin_country",
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("origin_country"),
          editable: {
            type: "select",
            options: this.origin_countries,
            field: "origin",
            empty: {
              id: null,
              label: "[Nuk ka]"
            }
          },
        },
        {
          headerName: "Menaxher",
          resizable: true,
          field: "manager_name",
          editable: {
            type: "select",
            options: this.managers,
            field: "manager",
            empty: {
              id: '',
              label: "[Nuk ka]"
            }
          },
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("manager_name"),
        },
        {
          headerName: "Klasa e energjise",
          resizable: true,
          field: "energy_efficiency_name",
          editable: {
            type: "select",
            options: this.energy_efficiency,
            field: "energy_efficiency",
            empty: {
              id: '',
              label: "[Nuk ka]"
            }
          },
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("warranty_name"),
        },
        {
          headerName: "Garancia",
          resizable: true,
          field: "warranty_name",
          editable: {
            type: "select",
            options: this.warranties,
            field: "warranty_id",
            empty: {
              id: '',
              label: "[Nuk ka]"
            }
          },
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("warranty_name"),
        },
        {
          headerName: "Paketimi",
          resizable: true,
          field: "packaging",
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("packaging"),
        },
        {
          headerName: "Ka garanci Airbag",
          resizable: true,
          field: "warranty_airbag",
          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("warranty_airbag"),
        },
        {
          headerName: "Ne web",
          resizable: true,
          field: "in_web_name",
          editable: {
            type: "select",
            options: [
              { id: 1, label: "Po" },
              { id: 0, label: "Jo" },
            ],
            field: "in_web",
            empty: {
              id: '',
              label: "[Pa percaktuar]"
            }
          },

          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("in_web_name"),
        },
        {
          headerName: "Statusi",
          resizable: true,
          field: "status",
          editable: {
            type: "select",
            options: [
              { id: 1, label: "Aktiv" },
              { id: 0, label: "Jo aktiv" },
            ],
            field: "status",
            empty: {
              id: '',
              label: "[Pa percaktuar]"
            }
          },

          filter: true,
          floatingFilter: false,
          sortable: true,
          // show_with_form: true,
          hide: this.hiden_columns.includes("status"),
        },
        ...services_col_data,
        {
          headerName: "Action",
          field: "actions",
          custom_component: "actions.vue",
          is_custom_component: true,
          resizable: true,
          cellRendererParams: this.context,
          // show_with_form: true,
          pinned: 'right',
          hide: this.hiden_columns.includes("action"),
        },
      ];
      // if (this.form_edit || this.form_create) {
      //   return [...aa.filter(i => i.show_with_form)]
      // }

      return [...aa];
    },
  },
  async created() {
    if (!this.am_allowed("product.can_see_product")) {
      window.location.href = "/home";
    }
    this.localeText = locale.locale_al();
    this.rowData.value = await ProductsServices.getProducts();
    this.product_recipes = await ProductsServices.getProductRecipes();
    // this.gridApi.sizeColumnsToFit();
    this.$root.setCurrentRoute("katalog-produkte");
    this.managers = await ProductServices.getManagers();

    // Get products_origin list
    this.origin_countries = await ProductServices.getOriginCountries();

    this.warranties = await ProductsServices.getWarranties();
    var energy_efficiency = await ConfigurationService.getEnergyEfficiency()
    this.energy_efficiency = energy_efficiency.map(r => {
      r.label = r.name
      r.id = r.guid
      return r
    })

    // Add services to grid
    this.services_to_grid();

    // Hiden_columns in localstorage
    if (localStorage.hiden_columns) {
      this.hiden_columns = JSON.parse(localStorage.hiden_columns)
    }
    // get warehouses
    this.warehouses_list = await WarehousesServices.getWarehouses()
  },
  onBeforeDestroy() {
    socket.disconnect();
  },
  mounted() { },
};
</script>

<style>
.type_id_name,
.accessory_id_name,
.transportable,
.mountable {
  color: rgb(235, 8, 8);
}

/* grid - form - single structure */
.half-height {
  height: 50vh;
}

.overfllow-y-scroll {
  overflow-y: scroll;
  position: relative;
}

.ag-root-wrapper.ag-layout-normal {
  height: 40vh;
}

@media (max-width: 767px) {

  /* Your styles for screens below 768px width */
  .overfllow-y-scroll-mobile {
    overflow-y: scroll;
  }
}
</style>
