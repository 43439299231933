<template>
  <div class="flex flex-col" style=" min-height: calc(100vh - 200px)">
    <div v-if="Object.keys(selectedRows).length > 0" class="flex items-center mb-1 bulk-fonts">
      <a href="#"
        @click.prevent="onlySelected = !onlySelected; editMode = onlySelected ? editMode : false; currentPage = 1;"
        class="dropdown-trigger mr-1">
        <i class="fa" :class="[
      onlySelected ? 'bg-green-100 fa-check-square' : 'fa-square text-gray-400'
    ]"></i>
      </a>
      Te zgjedhura: <span v-if="onlySelected" class="ml-1">{{ sortedData.length }} /</span> {{
      Object.keys(selectedRows).length }}
      <a href="#" v-if="onlySelected" @click.prevent="editMode = !editMode" class="dropdown-trigger ml-4">
        <i class="fa" :class="[editMode ? 'bg-green-100 fa-check-square' : 'fa-square text-gray-400']"></i>
        <i class="fa fa-edit ml-2"></i> Edit Mode
      </a>
      <div v-if="editMode" class="flex ml-3 items-end">
        <div v-for="header in headers.filter(h => h.editable)" :key="header.field"
          class="flex flex-col items-start border-r px-1 relative border-l">

          <label>
            {{ header.headerName }}
          </label>
          <div v-if="header.editable.modules" class="flex gap-1">
            <select v-model="header.editable.operation_value" v-if="header.editable.modules.operation"
              class="w-8 py-0 text-xs border border-gray-300 rounded-md focus:outline-none focus:border-blue-500">
              <option value="add">+</option>
              <option value="sub">-</option>
            </select>
            <input v-model="header.editable.value" type="text"
              class="w-16 text-sx border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
            <select v-model="header.editable.operation_mode"
              class="w-12 py-0 text-xs border border-gray-300 rounded-md focus:outline-none focus:border-blue-500">
              <option value="number">#</option>
              <option value="percentage">%</option>
            </select>
          </div>
          <div v-else>
            <select v-if="header.editable.type == 'select'" v-model="header.editable.value"
              class="block w-32  py-0 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500">
              <option :value="header.editable.empty.id" v-if="header.editable.empty">{{ header.editable.empty.label }}
              </option>
              <option value="" v-else>-- Zgjidh --</option>
              <option v-for="option in header.editable.options" :key="option" :value="option.id">{{ option.label }}
              </option>
            </select>
            <input v-else-if="header.editable.type == 'text'" v-model="header.editable.value"
              class="block w-32  py-0 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
            <input v-else-if="header.editable.type == 'number'" v-model="header.editable.value"
              class="block w-32  py-0 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />

          </div>
          <div v-if="hidden_columns.includes(header.field)" class="absolute top-0 bottom-0 w-full"
            style="background: rgb(255 255 255 / 78%)">
          </div>
        </div>
        <button @click="applyChanges"
          class="ml-1 w-16 px-2 py-0 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          title="Ndrysho vlerat">
          <i class="fa fa-check text-blue-500"></i>
        </button>
        <button @click="saveChanges"
          class="ml-1 mr-1 w-16 px-2 py-0 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          title="Ruaj">
          <i class="fa fa-save text-green-700"></i>
        </button>
        <button @click="restoreDefaultPrices"
          class="ml-1 w-16 px-2 py-0 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          title="Kthe cmimin dhe dhe vlerat e tjera baze te liste">
          <i class="fa fa-undo"></i>
        </button>
      </div>
    </div>
    <table class="w-full bg-white border border-gray-200 " style="width: 100%;">
      <thead class="select-none">
        <tr>
          <th style="width: 30px;">
            <a @click="selectAll()" class="dropdown-trigger">
              <i v-if="Object.keys(selectedRows).length == 0" class="fa fa-check-square"></i>
              <i v-else class="fa fa-square"></i>

            </a>
          </th>
          <th v-for="(header, index) in tableheaders.filter(h => h.hide != true)" :key="index"
            class="resizable text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider cursor-pointer align-bottom "
            style="vertical-align: top;" :style="{ width: header.width ?? 'auto' }" @drop="onDrop(index)">

            <div>
              <div class="flex justify-between" :draggable="!resizing" @dragstart="onDragStart(index)"
                @dragend="onDragEnd" @dragover.prevent="onDragOver($event)" @dragleave="onDragLeave($event)"
                @contextmenu.prevent="groupBy(header.field)">

                <a v-if="header?.filter && !activeFilters[header.field]" @click="toggleFilter(header.field)"
                  class="dropdown-trigger">
                  <i class="fa fa-filter" :class="[activeFilters[header.field] ? 'text-green-500' : '']"></i>
                </a>

                <span v-if="header?.field == 'actions'">
                  <i class="fa fa-cog"></i>
                </span>
                <span v-else-if="header?.field == 'id'">
                  <i class="fa fa-hashtag"></i>
                </span>
                <span class="flex-1 ml-1" @click="sortBy(header.field)" v-if="!activeFilters[header.field]">
                  {{ header.headerName }}
                </span>

                <span @click="sortBy(header.field)" v-if="sortKey === header?.field && !activeFilters[header.field]">
                  {{ sortOrders[header?.field] > 0 ? '▲' : '▼' }}
                </span>
                <span v-if="groupedBy?.includes(header?.field)">
                  <!-- fa group icon -->
                  <i class="fa fa-object-group text-blue-300 ml-1"></i>
                </span>
              </div>
              <div class="w-full ">
                <div class="flex gap-2 w-full items-center" v-if="header?.filter"
                  :class="[activeFilters[header.field] ? 'bg-gray-50' : '']">
                  <a v-if="header?.filter && activeFilters[header.field]" @click="toggleFilter(header.field)"
                    class="dropdown-trigger rounded-full bg-green-50">
                    <i class="fa fa-search" :class="[activeFilters[header.field] ? 'text-green-500' : '']"></i>
                  </a>
                  <div v-if="activeFilters[header.field]" class="flex flex-row w-full gap-1">
                    <!-- <input v-if="!header.floatingFilter" class="p-1 border border-gray-200" type="text"  -->
                    <!--   v-model="header.searchFilter" placeholder="Kerko" /> -->
                    <select v-if="!header.floatingFilter" class="px-1 border border-gray-200"
                      v-model="activeFilters[header.field].value">
                      <option value="">{{ header.headerName }}...</option>
                      <option v-for="filter in Object.keys(filters[header.field])" :key="filter" :value="filter">
                        {{ filter }} ({{ filters[header.field][filter].count }})
                      </option>
                    </select>
                    <input v-else class="px-1 border border-gray-200" type="text"
                      v-model="activeFilters[header.field].value" :placeholder="header.headerName + '...'"
                      @keydown="reset2FirstPage()" />

                    <span @click="sortBy(header.field)" v-if="sortKey === header?.field">{{ sortOrders[header?.field] >
      0 ? '▲' : '▼' }}</span>
                  </div>
                </div>
              </div>
              <div class="resizer" @mousedown="startResizing($event, index)"></div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in rows" :key="row.id">
          <tr v-if="row.isGroup" class="border-b-2 border-blue-100 hover:bg-blue-50 " style="max-height: 50px">
            <td></td>
            <td :colspan="tableheaders.filter(h => h.hide != true).length">
              <button @click="toggleGroup(row.groupKey)">
                <!-- Display a different icon or text based on the collapsed state -->
                <span class="mr2" v-if="row.collapsed">[+]</span>
                <span class="mr-1" v-else>[-]</span>
              </button>
              <template v-for="(groupName, index) in row.groupNames" :key="index">
                <strong>{{ groupName.value }}</strong><br>
              </template>
            </td>
          </tr>
          <template v-else>
            <tr class="border-b border-gray-100" style="max-height: 50px">
              <td style="vertical-align: middle;">

                <!-- if is first column selectableColumn == true shows checkbox to make table selectable -->
                <a href="#" @click.prevent="select(row)" class="dropdown-trigger">
                  <i class="fa" :class="[
      selectedRows['row-' + row.id] ? 'bg-green-100 fa-check-square' : 'fa-square text-gray-400'
    ]"></i>
                </a>

              </td>
              <td v-for="(header, index) in tableheaders.filter(h => h.hide != true)" :key="index"
                class="resizable align-bottom"
                :class="{ 'bg-red-100': entity == 'product' && row.columns.status == 0 }">
                <div class="resizable truncate">
                  <div v-if="typeof header.cellRenderer === 'function' && editMode == false"
                    v-html="header.cellRenderer({ data: row.cells })">
                  </div>

                  <div v-else-if="typeof header.cellRenderer === 'function' && editMode == true">
                    <input v-model="selectedRows['row-' + row.id].cells[header.field]" class="input is-small"
                      v-if="header.editable" :type="header.editable.type"
                      :class="[data.find(d => d.id == row.id)[header.field] != selectedRows['row-' + row.id].cells[header.field] ? 'is-edited' : '']" />
                    <div v-else class="input is-small border-gray-100 overflow-hidden">
                      <div class="truncate text-clip-border" v-html="row.cells[header.field]" :class="[
      row.columns[header.field] == '[Pa Percaktuar]' ? 'text-gray-200' : '',
    ]"></div>
                    </div>

                  </div>

                  <div v-else-if="header.is_custom_component && !editMode">
                    <!-- dynamically import component in header.custom_component -->
                    <component :is="resolveComponent(header.custom_component)" :data="row.columns"></component>
                  </div>
                  <div v-else-if="typeof header.cellRenderer !== 'function'">
                    <div v-if="!editMode" :class="[
      row.columns[header.field] == '[Pa Percaktuar]' ? 'text-gray-200' : '',
    ]">
                      {{ row.cells[header.field] }}
                    </div>
                    <div v-else-if="editMode && header.editable && header.editable.type != 'select'">
                      <input v-model="selectedRows['row-' + row.id].cells[header.field]" class="input is-small"
                        :type="header.editable.type" />
                    </div>
                    <div v-else-if="editMode" class="input is-small border-gray-100 overflow-hidden"
                      :class="[data.find(d => d.id == row.id)[header.field] != selectedRows['row-' + row.id].cells[header.field] && header.editable ? 'is-edited' : '']">
                      <div class="truncate text-clip-border">
                        {{ row.cells[header.field] }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
    <div class="flex gap-1 w-full justify-center mt-1 items-center mt-auto" v-if="onlySelected == false">
      <!-- show all pages -->
      <div class="ml-auto">
        <span v-for="page in visiblePages" :key="page">
          <button @click="handlePageClick(page)"
            class="bg-white hover:bg-gray-100 text-gray-800 font-light py-1 px-2 mx-1 border border-gray-300 rounded shadow mt-2 is-size-7"
            :class="{ 'bg-blue-100': currentPage === page }">{{ typeof page === 'object' ? page.label : page }}</button>
        </span>
      </div>
      <small title="total rows" class=" text-gray-400 font-light py-1 px-2  rounded  mt-2 is-size-7 ml-auto">{{
        formatNumbers(sortedData.length) }} rekorde</small>
    </div>
  </div>
</template>

<script>
import { ref, computed, markRaw, watchEffect } from 'vue';
import numeral from 'numeral';
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true
    },
    currPage: {
      type: Number,
      default: 1
    },
    entity: {
      type: String
    },
    hidden_columns: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    headers: {
      handler: function (val) {
        this.tableheaders = [...val];
      },
      deep: true
    },
    currPage: {
      handler: function (val) {
        this.currentPage = val;
      },
      deep: true
    },
    // data: {
    //   handler: function (val) {
    //     this.rows = [...val];
    //   },
    //   deep: true
    // },
  },

  methods: {
    restoreDefaultPrices() {

      this.rows.map(row => {

        this.headers.map(h => {
          if (h.editable) {
            var exists = this.selectedRows['row-' + row.id].columns['default_' + h.field]
            if (exists)
              this.selectedRows['row-' + row.id].cells[h.field] = this.selectedRows['row-' + row.id].columns['default_' + h.field];
          }
        })
      });
    },
    applyChanges() {
      this.rows.map(row => {


        this.headers.map(h => {
          if (h.editable && !this.hidden_columns.includes(h.field)) {

            if (this.selectedRows['row-' + row.id] && h.editable.value != null) {
              if (h.editable.type == 'number') {
                if (h.editable.modules) {
                  if (h.editable.field == 'price') {
                    if (h.editable.operation_mode == 'number') {
                      if (h.editable.operation_value == 'add') {
                        this.selectedRows['row-' + row.id].cells[h.field] += parseInt(h.editable.value);
                      } else if (h.editable.operation_value == 'sub') {
                        this.selectedRows['row-' + row.id].cells[h.field] -= parseInt(h.editable.value);
                      }
                    } else if (h.editable.operation_mode == 'percentage') {
                      if (h.editable.operation_value == 'add') {
                        this.selectedRows['row-' + row.id].cells[h.field] += parseFloat(h.editable.value) / 100 * this.selectedRows['row-' + row.id].cells[h.field];
                      } else if (h.editable.operation_value == 'sub') {
                        this.selectedRows['row-' + row.id].cells[h.field] -= parseFloat(h.editable.value) / 100 * this.selectedRows['row-' + row.id].cells[h.field];
                      }
                    }
                  }
                  else
                    this.selectedRows['row-' + row.id].cells[h.field] = parseInt(h.editable.value);
                } else
                  this.selectedRows['row-' + row.id].cells[h.field] = parseInt(h.editable.value);
              }
              else this.selectedRows['row-' + row.id].cells[h.field] = h.editable.value;
              if (h.editable.type == 'select') {
                this.selectedRows['row-' + row.id].cells[h.field] = h.editable.options.find(o => o.id == h.editable.value)?.label ?? h.editable.empty.label;
                this.selectedRows['row-' + row.id].cells[h.editable.field] = h.editable.value;
              }
            }


          }
        })

      })

    },
    saveChanges() {
      console.log('save changes')
      var changes = {}
      this.rows.map(row => {
        if (this.selectedRows['row-' + row.id] && Object.keys(this.selectedRows['row-' + row.id].cells).length > 0) {
          Object.keys(this.selectedRows['row-' + row.id].cells).map(cell => {
            if (!this.hidden_columns.includes(cell))
              Object.keys(this.selectedRows['row-' + row.id].columns).map(column => {
                if (cell == column) {
                  if (this.selectedRows['row-' + row.id].cells[cell] != this.selectedRows['row-' + row.id].columns[column]) {
                    if (!changes[row.id]) {
                      changes[row.id] = {}
                    }
                    // if cell is a header editable field
                    if (this.headers.find(h => h.field == cell && h.editable)) {
                      var c = cell
                      if (this.headers.find(h => h.field == cell && h.editable.type == 'select')) {
                        c = this.headers.find(h => h.field == cell && h.editable.type == 'select').editable.field
                      }
                      changes[row.id][c] = this.selectedRows['row-' + row.id].cells[c]
                    }
                  }
                }
              })
          })
        }
      })
      // console.log(changes)
      this.$emit('save-changes', changes)
    },
    select(row) {
      if (this.selectedRows['row-' + row.id]) {
        delete this.selectedRows['row-' + row.id];
      } else {
        this.selectedRows['row-' + row.id] = row;
      }
    },
    selectAll() {
      this.rows.forEach(row => {
        if (!this.selectedRows['row-' + row.id]) {
          this.selectedRows['row-' + row.id] = row;
        } else {
          delete this.selectedRows['row-' + row.id];
        }

      });

      if (Object.keys(this.selectedRows).length == 0) {
        this.onlySelected = false;
      }
    },
    reset2FirstPage() {
      // reset to the first page if the current page is >= than the total pages 
      if (this.currentPage >= this.totalPages) {
        this.currentPage = 1;
      }
    },
    formatNumbers(number) {
      return numeral(number).format('0,0');
    },
    resolveComponent(componentName) {
      return this.customComponents[componentName];
    },
    handlePageClick(page) {
      if (typeof page === 'object') {
        if (page.direction === 'forward') {
          this.currentPage = Math.min(this.currentPage + 3, this.totalPages);
        } else {
          this.currentPage = Math.max(this.currentPage - 3, 1);
        }
      } else {
        this.currentPage = page;
      }
    },
  },
  setup(props) {
    const searchFilter = ref('');
    const customComponents = ref({});
    var currentPage = ref(1);
    var selectedRows = ref({})
    var onlySelected = ref(false);
    var editMode = ref(false);



    watchEffect(() => props, (newValue, oldValue) => {
      console.log('Value changed:', newValue, 'Old Value:', oldValue);
      currentPage = newValue; // Update the previous value
    });

    const activeFilters = ref({});
    const toggleFilter = (field) => {
      if (activeFilters.value[field]) {
        activeFilters.value[field] = null;
      } else {
        activeFilters.value[field] = {
          field,
        };
      }
    };

    const itemsPerPage = ref(35);
    const collapsedGroups = ref({});
    const toggleGroup = (groupKey) => {
      collapsedGroups.value[groupKey] = !collapsedGroups.value[groupKey];
    };
    // Load custom components when the component is created
    props.headers.forEach(header => {
      if (header.custom_component) {
        import(`@/components/dables/${header.custom_component}`)
          .then(module => {
            customComponents.value[header.custom_component] = markRaw(module.default);
          });
      }
    });

    // computed property of all the filters per column 
    // from the props.data 
    const filters = computed(() => {
      const filters = {};
      props.headers.forEach(header => {
        if (header.filter) {
          filters[header.field] = (
            Object.keys(selectedRows.value).length > 0 && onlySelected.value
              ? Object.keys(selectedRows.value).map(r => selectedRows.value[r].cells)
              : props.data
          )
            .reduce((obj, item) => {
              if (!obj[item[header.field]]) {
                obj[item[header.field]] = {
                  field: header.field,
                  value: item[header.field],
                  is_select: !header.floatingFilter,
                  count: 0,
                };
              }
              obj[item[header.field]].count++;
              return obj;
            }, {});

          // sort the filters by field value 
          filters[header.field] = Object.entries(filters[header.field]).sort((a, b) => {
            return a[1].value > b[1].value ? 1 : -1;
          }).reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        }
      });
      return filters;
    });




    const totalPages = computed(() => Math.ceil((Object.keys(selectedRows.value).length > 0 && onlySelected.value ? Object.keys(selectedRows.value).length : groupedRows.value.length) / itemsPerPage.value));
    const visiblePages = computed(() => {
      const pages = [];
      const startPage = Math.max(1, currentPage.value - 3);
      const endPage = Math.min(totalPages.value, currentPage.value + 3);

      if (startPage > 1) pages.push(1);
      if (startPage > 2) pages.push({ label: '...', direction: 'backward' });

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages.value - 1) pages.push({ label: '...', direction: 'forward' });
      if (endPage < totalPages.value) pages.push(totalPages.value);

      return pages;
    });

    const resizing = ref(false);
    const tableheaders = ref([...props.headers]);
    const sortKey = ref(null);
    const sortOrders = ref(
      props.headers
        .map(header => header.field)
        .filter((value, index, self) => self.indexOf(value) === index)
        .reduce((orders, field) => {
          orders[field] = 1;
          return orders;
        }, {})
    );

    const paginatedRows = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return (Object.keys(selectedRows.value).length > 0 ? Object.keys(selectedRows.value).map(r => selectedRows[r]) : rows.value).slice(start, end);
    });
    const nextPage = () => {
      currentPage.value = Math.min(Math.ceil(rows.value.length / itemsPerPage.value), currentPage.value + 1);
    };

    const prevPage = () => {
      currentPage.value = Math.max(1, currentPage.value - 1);
    };

    // whole list
    const sortedData = computed(() => {
      const sortKeyVal = sortKey.value;
      const order = sortOrders.value[sortKeyVal] || 1;

      // check if there are filters set 
      // if so, filter the data first 
      // then sort it 
      let filtered = [...((Object.keys(selectedRows.value).length > 0 && onlySelected.value)
        ? Object.keys(selectedRows.value).map(r => { return { ...selectedRows.value[r].cells, id: selectedRows.value[r].id } })
        : props?.data) ?? []];
      if (Object.keys(activeFilters.value).length > 0) {
        Object.keys(activeFilters.value).forEach(key => {
          const filter = activeFilters.value[key];
          // filter relative to the filter type  
          if (filter && filter.value) {
            filtered = filtered.filter(
              item => filters.value[key][filter.value]?.is_select
                ? item[filter.field]?.toLowerCase() === filter.value.toLowerCase()
                : item[filter.field]?.toLowerCase().includes(filter.value.toLowerCase())
            );
          }
        });
      }
      let sorted = [...filtered ?? []];
      if (sortKeyVal) {
        sorted = sorted.sort((a, b) => {
          a = a[sortKeyVal] ?? '';
          b = b[sortKeyVal] ?? '';
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return sorted;
    });

    const groupedBy = ref([]);

    // New computed property to handle grouped data
    const groupedRows = computed(() => {
      let result = [];
      if (groupedBy.value.length > 0) {
        const groupedEntries = Object.entries(groupedData.value);

        // Sort the groups based on the group names
        const sortedGroups = groupedEntries.sort((a, b) => {
          return sortKey.value ? (a[0] > b[0] ? 1 : -1) * sortOrders.value[sortKey.value] : 0;
        });

        // Process the sorted groups
        sortedGroups.forEach(([group, items]) => {
          const groupParts = group.split(' | ');
          const groupKey = groupParts.join(' | '); // Unique key for the group
          const groupNames = groupedBy.value.map((groupKey, index) => ({
            key: groupKey,
            value: groupParts[index],
          }));
          result.push({
            isGroup: true,
            groupNames,
            collapsed: collapsedGroups.value[groupKey],
            groupKey
          });

          // Sort the items within the group if not collapsed
          if (!collapsedGroups.value[groupKey]) {
            const sortedItems = sortKey.value
              ? items.sort((a, b) => (a[sortKey.value] > b[sortKey.value] ? 1 : -1) * sortOrders.value[sortKey.value])
              : items;

            sortedItems.forEach(item => {
              result.push({
                id: item.id,
                columns: item,
                cells: /* props.headers.reduce((obj, header) => {
                  obj[header.field] = item[header.field];
                  return obj;
                }, {}) */ { ...item },
              });
            });
          }
        });
      } else {
        result = sortedData.value.map(item => ({
          id: item.id,
          columns: item,
          cells: /* props.headers.reduce((obj, header) => {
            obj[header.field] = item[header.field] ?? '';
            return obj;
          }, {}) */ { ...item },
        }));
      }
      return result;
    });

    const groupedData = computed(() => {
      if (groupedBy.value.length === 0) return [];

      return sortedData.value.reduce((groups, item) => {
        const key = groupedBy.value.map(groupKey => item[groupKey]).join(' | ');
        if (!groups[key]) groups[key] = [];
        groups[key].push(item);
        return groups;
      }, {});
    });
    // Computed property for rows taking pagination into account
    const rows = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return (groupedRows.value)
        .slice(start, end);
    });
    const sortBy = key => {
      if (sortKey.value === key) {
        sortOrders.value[key] = sortOrders.value[key] * -1;
      } else {
        sortKey.value = key;
        sortOrders.value[key] = 1;
      }
    };

    const groupBy = key => {
      const index = groupedBy.value.indexOf(key);
      if (index === -1) {
        groupedBy.value.push(key);
      } else {
        groupedBy.value.splice(index, 1);
      }
    };


    const resizingColumnIndex = ref(-1);

    const startResizing = (event, index) => {
      resizing.value = true;
      resizingColumnIndex.value = index;

      // = event.target.getBoundingClientRect().width + 'px';
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', stopResizing);
    };

    const handleMouseMove = event => {
      const tableHeaders = document.querySelectorAll('.resizable');
      if (resizingColumnIndex.value > -1 && tableHeaders[resizingColumnIndex.value]) {
        const newWidth = event.clientX - tableHeaders[resizingColumnIndex.value].getBoundingClientRect().left;
        tableHeaders[resizingColumnIndex.value].style.width = newWidth + 'px';
        document.body.classList.add('resizing');
      }
    };

    const stopResizing = () => {
      resizingColumnIndex.value = -1;
      resizing.value = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', stopResizing);
      document.body.classList.remove('resizing');
    };

    const onDragStart = index => {
      const header = tableheaders.value.filter(h => h.hide != true)[index];
      if (header) {
        event.dataTransfer.setData('text/plain', tableheaders.value.indexOf(header));
        event.target.closest('th').classList.add('dragging');
      }
    };
    const onDragEnd = () => {
      const draggingHeader = document.querySelector('.dragging');
      if (draggingHeader) draggingHeader.classList.remove('dragging'); // Remove class from dragged header
    };

    const onDragOver = event => {
      event.target.closest('th').classList.add('hover-to-drop'); // Add class to hovered header
    };

    const onDragLeave = event => {
      event.target.closest('th').classList.remove('hover-to-drop'); // Remove class from hovered header
    };
    const onDrop = index => {
      const draggedIndex = event.dataTransfer.getData('text/plain');
      if (draggedIndex !== index) {
        [tableheaders.value[draggedIndex], tableheaders.value[index]] = [tableheaders.value[index], tableheaders.value[draggedIndex]]; // Update internal state
        // force update to re-render 
        tableheaders.value = [...tableheaders.value];
      }
      const hoverToDropHeader = document.querySelector('.hover-to-drop');
      if (hoverToDropHeader) hoverToDropHeader.classList.remove('hover-to-drop'); // Remove class from hovered header
    };
    return {
      rows,
      tableheaders,
      sortBy,
      sortKey,
      sortOrders,
      sortedData,
      groupBy,
      groupedBy,
      startResizing,
      onDragStart,
      onDragEnd,
      onDragOver,
      onDragLeave,
      onDrop,
      resizing,
      customComponents,
      paginatedRows,
      currentPage,
      nextPage,
      prevPage,
      totalPages,
      visiblePages,
      toggleGroup,
      toggleFilter,
      activeFilters,
      filters,
      searchFilter,
      selectedRows,
      onlySelected,
      editMode,
    };
  }
};
</script>

<style scoped>
/*  */
table {
  border-collapse: collapse;
  border-spacing: 4px;
  border: 1px solid #ddd;
}

th,
td {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  vertical-align: bottom;
  font-size: 12px;
}

/* th div { */
/*   resize: horizontal; */
/*   overflow: auto; */
/*   text-overflow: ellipsis; */
/*   width: auto; */
/*   height: auto; */
/* } */


td {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  vertical-align: bottom;
  font-size: 12px;
}

.cell-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resizable {}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  border-right: 2px solid #ddd;
  border-left: 2px solid #ddd;
  cursor: ew-resize;
  background: #fff;
}

.resizing {
  cursor: ew-resize;
}

.dragging {
  background-color: #f3f3f3;
  /* or any other color for the dragged header */
  opacity: 0.7;
}

.bulk-fonts {
  font-size: 12px;
}

.hover-to-drop {
  background-color: #e0e0e0;
  /* or any other color for the hovered header */
  border: 2px dashed #666;
  /* optional: dashed border for the drop target */
}

.is-edited {
  background-color: yellow;
}

.truncate {
  /* Set overflow to hidden */
  overflow: hidden;
  /* Set white-space to nowrap to prevent text wrapping */
  white-space: nowrap;
  /* Set text-overflow to ellipsis to indicate that text has been truncated */
  text-overflow: ellipsis;
  /* Optionally, specify the width of the container to control where the text truncates */
  width: auto;
  /* Adjust as needed */
}
</style>
