<template>
  <div class="product-stock-wrap">
    <div v-if="loading" class="text-center py-4 ">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
    <div v-else-if="stock.length > 0" class="pt-2">
        <table class="table is-bordered is-stripped is-narrow is-hoverable is-fullwidth">
            <thead class="is-size-7 is-uppercase bg-gradient-to-r from-emerald-50 to-emerald-200 hover:from-emerald-100 hover:to-emerald-300">
                <tr>
                    <th width="70%">Magazina</th>
                    <th>Stok</th>
                    <th>Ditar</th>
                    <th>Proces</th>
                    <th>
                        Disponueshme
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="stock in Object.keys(stock_data)" :key="stock">
                    <td>
                        {{stock_data[stock].name}}
                    </td>
                    <td>
                        {{stock_data[stock].stock}}
                    </td>
                    <td>
                        {{stock_data[stock].diary_amount}}
                    </td>                   
                    <td>
                        {{stock_data[stock].process_amount}}
                    </td>
                    <td class="bg-green-50">
                        {{ stock_data[stock].stock }}
                    </td>
                </tr>
            </tbody>

        </table>

    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
export default {
    props: ['product'],
    data() {
        return {
            stock: [],
            product_extended_detail: [],
            loading: false
        }
    },
    created(){
        this.get_product_stock()
    },
    methods: {
        get_product_stock(){
            this.loading = true
            Api(true).post("/product/get/stock", {
                product_id: this.product.guid
            }).then(res => {
                this.loading = false
                this.stock = res.data[0]
                this.product_extended_detail = res.data[1]

                this.stock = [...this.stock.map(s => {

                    this.product_extended_detail[1].map(p => { // in process amount 
                        if(s.product_id == p.product_id && (s.warehouse_id == p.warehosue_in || s.warehouse_id == p.warehosue_out)) {
                            s  = {...s, process: p}
                        }
                    })

                    this.product_extended_detail[2].map(p => { // in diary amount
                        if(s.product_id == p.product_id && (s.warehouse_id == p.warehosue_in || s.warehouse_id == p.warehosue_out)) {
                            s  = {...s, diary: p}
                        }
                    })

                    return s
                })]
            })
        }
    },
    computed: {
        stock_data(){
            var stock = {}


            this.stock.map(s => {
                if(!stock[s.warehouse_id])
                    stock[s.warehouse_id] = {
                        name: s.warehouse_name, 
                        stock: this.stock.reduce((amount, st) => {
                            return amount + (
                                (st.warehouse_id == s.warehouse_id && st.type == 'stock') 
                                ? parseFloat(st.amount) 
                                : 0)
                        }, 0), 
                        process_amount: this.product_extended_detail[1].reduce((amount, st) => {
                            return amount + (
                                ((st.warehouse_in || st.warehouse_out) == s.warehouse_id) 
                                ? parseFloat(st.amount) 
                                : 0)
                        }, 0),
                        diary_amount: this.product_extended_detail[2].reduce((amount, st) => {
                            return amount + (
                                ((st.warehouse_in || st.warehouse_diary_out) == s.warehouse_diary_id) 
                                ? parseFloat(st.amount) 
                                : 0)
                        }, 0),

                         
                    }
            })

            this.product_extended_detail[1].map(p => {
                var fount = false;
                this.stock.map(s => {
                    if(s.warehouse_id == p.warehouse_in || s.warehouse_id == p.warehouse_out) 
                    fount = true
                })
                if(fount == false) stock[p.warehouse_in || p.warehouse_out] = /* warehouse name  */ {...p, process_amount: p.amount, name: this.$parent.getWarehouseName(p.warehosue_in || p.warehouse_out)};
            })
            
            this.product_extended_detail[2].map(d => {
                if(!stock[d.warehouse_diary_in || d.warehouse_diary_out]){
                stock[d.warehouse_diary_in || d.warehouse_diary_out] = /* warehouse name  */ {...d, diary_amount: d.amount, name: this.$parent.getWarehouseName(d.warehouse_diary_in || d.warehouse_diary_out) + ''};
        
                } else {
                    if(!stock[d.warehouse_diary_in || d.warehouse_diary_out].diary_amount  ){ 
                        stock[d.warehouse_diary_in || d.warehouse_diary_out] =  {...stock[d.warehouse_diary_in || d.warehouse_diary_out], 'diary_amount': d.amount, name: this.$parent.getWarehouseName(d.warehouse_diary_in || d.warehouse_diary_out) + ''}
                    }
                }
            })


            return stock
        }
    }
}
</script>

<style>

    .product-stock-wrap *{
        font-family: 'Varela', san-serif, FontAwesome; 
    }

</style>