import Api from "@/services/Api";

export default {
    async getServices() {
        var services = []
        await Api(true).post("service/list", {})
        .then(r => {
            services = r.data.map(ss => {
                ss.label = ss.name
                return ss
            })
        })
        return services
    },

    async createServices(service) {
        await Api(true).post("service/create", service)
        .then(r => {
            service = r.data
        })
        return service
    },

    async editServices(service) {
        await Api(true).post("service/edit", service)
        .then(r => {
            service = r.data
        })
        return service
    },
    async getServiceTypes() {
        var services_types = []
        await Api(true).post("service_types/get", {})
        .then(r => {
            services_types = r.data.map(ss => {
                ss.label = ss.name
                return ss
            })
        })
        return services_types
    },
}