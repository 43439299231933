import ProductsServices from "@/services/Product";

export default async function createProductRecipe() {

  var material = await ProductsServices.getMaterials();

      return {
        name: "Krijo Recete",
        submit: {
          label: "Krijo",
          action: "submitProductRecipe",
        },
        fields: [
          [
            {
                name: "material_id",
                label: "Materiali",
                type: "select-field",
                options: material,
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
              value: "",
              name: "quantity",
              label: "Sasia",
              type: "number-field",
              style: "flex-grow: 0.4",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
        ],
      };
    }
  
  