<template>
    <div>
        <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center"
            v-if="show_group_change_modal" @click.self="$emit('toggle_sublit_modal')">
            <!-- :style="useDiaryStore().search_item_mode ? {'overflow':'auto','max-height':'65%'} : {}" -->
            <div class="p-2 bg-white mt-20 flex-col products-search flex flex-col" 
                style="max-height: 63%; overflow: auto;  min-width:80%;">
                <!-- Search input -->
                <div class="flex items-top relative border-b mb-2">
                    Ruaj ndryshimet
                </div>
                <div class="flex flex-col text-14px">
                    <div class="flex flex-col">
                        <label class="text-slate-500" for="">Tipi i ndryshimit</label>
                        <select v-model="change_type" class="border rounded border-slate-400 mb-1" id="">
                            <option v-for="(type, index) in change_types" :key="index" 
                                :value="type.id">{{type.name}}</option>
                        </select>
                    </div>
                    <div class="flex flex-col mb-2">
                        <label class="text-slate-500" for="">Arsye per ndryshimin</label>
                        <textarea v-model="reason_text"
                            cols="30" rows="5" 
                            class="border rounded border-slate-400 px-1"></textarea>
                    </div>
                    <div class="flex">
                        <a @click.prevent="save_changes()" 
                        :class="[change_type == 0 || reason_text == '' ? 'opacity-50' : '']"
                        class=" w-full bg-green-400 border border-green-800 rounded text-center text-white py-1 font-semibold" href=""><i class="fa-solid fa-check mr-1"></i> Ruaj</a>
                    </div> 
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Api from "@/services/Api";
export default {
    
    props: ['show_group_change_modal'],
    data() {
        return {
            change_types: [],
            change_type: 0,
            reason_text: ''
        }
    },
    created(){
        // Get change types
        Api(true).post('product/get/change/types')
        .then(res => {
            this.change_types = res.data
        })
    },
    methods: {
        save_changes(){
            if(this.change_type == 0 || this.reason_text == '') return
            this.$emit('save_changes', {
                change_type: this.change_type,
                reason_text: this.reason_text
            })
        }
    }
}
</script>

<style>

</style>