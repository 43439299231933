<template>
    <div class="flex flex-col">
        <div class="text-center flex">
            <input v-model="search_val" type="text" class="border-slate-200 rounded border px-1 text-14px w-48 my-2" placeholder="Kerko">
        </div>
        <div>
            <table class="table is-narrow is-fullwidth">
                <thead>
                    <tr>
                        <th>Krijuar nga</th>
                        <th>Krijuar me</th>
                        <th>Statusi</th>
                        <th>Kodi i ditarit</th>
                        <th>POS</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(diary, index) in diary_filtered.filteredDiaries" :key="index" class="text-14px">
                        <td>{{diary.created_by_name}}</td>
                        <td>{{diary.created_at}}</td>
                        <td>{{diary.status_name}}</td>
                        <td>{{diary.code}}</td>
                        <td>{{diary.pos_name}}</td>
                        <td>
                            <a @click.prevent="openDiary(diary.guid)" class="bg-teal-400 text-white px-2 rounded" href="">
                                <i class="fa-solid fa-share"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center items-center mt-4 space-x-2 text-14px">
                <button @click="prevPage"
                        :disabled="current_page === 1"
                        v-show="current_page > 1"
                        class="px-3 py-1 bg-blue-500 text-white rounded disabled:opacity-50"
                ><i class="fa-solid fa-angle-left"></i></button>
                <span>{{ current_page }}</span>
                <button @click.prevent="nextPage"
                    :disabled="current_page === diary_filtered.totalPages"
                    v-show="current_page < diary_filtered.totalPages"
                    class="px-3 py-1 bg-blue-500 text-white rounded disabled:opacity-50"
                ><i class="fa-solid fa-angle-right"></i></button>

            </div>
        </div>
    </div>
</template>

<script>
import ProductsServices from "@/services/Product";

export default {
    props: ['guid'],
    data() {
        return {
            diaries: [],
            search_val: '',
            current_page: 1,
            items_per_page: 6,
        };
    },
    async created() {
        this.diaries = await ProductsServices.get_product_diaries({
            guid: this.guid,
        });
    },
    computed: {
        diary_filtered() {
            const filteredDiaries = this.diaries.filter(diary => JSON.stringify(diary).toLowerCase().includes(this.search_val.toLowerCase()));
            const totalItems = filteredDiaries.length;
            const totalPages = Math.ceil(totalItems / this.items_per_page);
            const startIndex = (this.current_page - 1) * this.items_per_page;
            const endIndex = startIndex + this.items_per_page;
            return {
                filteredDiaries: filteredDiaries.slice(startIndex, endIndex),
                totalPages,
            };
        },
    },
    methods: {
        prevPage() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        },
        nextPage() {
            if (this.current_page < this.diary_filtered.totalPages) {
                this.current_page++;
            }
        },
    },
};
</script>

<script setup>
    import { useRouter } from "vue-router";
    const router = useRouter();

    // Methods
    const openDiary = (id) => {
        router.push({ name: "Ditar Shiko", params: { id } });
    };
</script>
