<template>
    <div class="product-details-wrap bg-slate-50 p-1" style="  ">
        <div class="flex flex-col is-justify-content-space-between"> 
            <!-- <span class="is-pulled-left"> Info </span> -->
            <div class="is-size-5">
                <small class="is-size-7">{{selected_item.brand_name}}</small>
                <p style="margin-top:-10px;" class="is-size-6">
                    {{selected_item.name}}
                </p>
            </div>
            <small class="text-slate-400 text-10px mr-2"># {{selected_item.code}}</small>
        </div>
        <div class="mb-4 is-size-7">
            <div class="is-flex is-justify-content-space-between mb-1 border-b-2 border-slate-100">
                <div v-if="selected_item.type_id">
                    <small class="text-slate-400">Kategoria</small> 
                    <p v-if="selected_item.categories.length > 0" v-html="selected_item.categories.map(
                        (v, index) => { 
                            if(index == 0) return '<b>'+v.name.toUpperCase()+'</b>'
                            return v.name.toUpperCase() 
                        }).reverse().join(' > ')">
                    </p>
                    <p v-else class="has-text-danger">
                        <small> Nuk ka kategori </small>
                    </p>
                </div>
                <!-- <div v-if="selected_item.code" class="has-text-right">
                    <small class="text-slate-400">Kodi</small> 
                    <p># {{selected_item.code}}</p>
                </div> -->
            </div>
            <div v-if="selected_item.description" class="border-b-2 border-slate-100">
                <small class="text-slate-400">Pershkrimi</small> 
                <p>{{selected_item.description}}</p>
            </div>
            <div  class="is-flex is-justify-content-space-between mb-1 border-b-2 border-slate-100">
                <div v-if="selected_item.unit_name" class="">
                    <small class="text-slate-400">Njesia</small>
                    <p>{{selected_item.unit_name}}</p>
                </div>
                <!-- <div v-if="selected_item.brand_name" class=" has-text-right">
                    <small class="text-slate-400">Marka</small>
                    <p>{{selected_item.brand_name}}</p>
                </div> -->
            </div>
            <div v-if="selected_item.attributes.length > 0" class="border-b-2 border-slate-100 mt-2">
                <!-- <small class="text-slate-400">Atribute</small> -->
                <table v-if="selected_item.attributes.length > 0" class="table is-striped is-hoverable is-fullwidth is-narrow is-bordered is-size-7">
                    <thead>
                        <tr style="background: #ecf9ff;">
                            <th width="20%" class="font-thin has-text-grey">Specifika</th>
                            <th class="font-thin has-text-grey">Vlera</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(attribute, index) in selected_item.attributes" :key="index">
                            <td >{{attribute.attribute_name}}</td>
                            <td>
                                <strong v-if="attribute.type_id != 'checkbox'" >
                                    {{ attribute.attribute_value.toUpperCase() }}
                                </strong>
                                <span v-else>
                                    <i v-if="attribute.attribute_value == 1" class="fa-regular fa-square-check"></i>
                                    <i v-else class="fa-regular fa-square-full"></i>

                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="selected_item.accessories.length > 0" class="border-b-2 border-slate-100 my-2">
                <p class="text-slate-400 px-2" style="background:#ecf9ff; color:#000; ">Aksesore</p>
                <div class="pl-4">
                    <div v-for="(category, index) in selected_item.accessories_categories" :key="index">
                        <p style="font-size:10px;">{{category.accessory_name}}</p>
                        <div v-for="(accessory, indexa) in selected_item.accessories.filter(a => a.accessory_id == category.accessory_id)" :key="indexa"
                            class="ml-2 mb-2">
                            <span>
                                {{accessory.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 border">
                <p class="text-slate-400 px-2 py-1" style="background:#ecf9ff; color:#000; ">Sherbime</p>
                <div class="pl-4">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr class="text-slate-200">
                                <td class="text-slate-200">Kategoria</td>
                                <td class="text-slate-200">Sherbimi</td>
                                <td class="text-slate-200">Cmimi</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="service in selected_item.services" :key="service.guid">
                                <td>{{service.category_name}}</td>
                                <td>{{service.service_name}}</td>
                                <td>{{ price_filter(service.service_price) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- unit_name, brand_name transportanle_service_name, mountable_service_name,  -->
        <small>
            <!-- <pre> {{attributes_per_item}} </pre> -->
        </small>
    </div>
</template>

<script>
var numeral = require('numeral');
export default {
    props: ['selected_item', 'attributes_per_item'],
    methods: {
        price_filter(price) {
            return numeral(price).format('0,0.00') + ' Lek'
        },
    },
    computed: {
    },

}
</script>

<style>
    .product-details-wrap *{
        font-family: 'Varela', san-serif, FontAwesome; 
    }
</style>
